import {
  AgencySalesperson,
  SessionInfo,
  SignerProxyType,
  SigningInitiator,
  SigningParty,
  SigningPartySourceType,
  SigningPartyType,
  SigningPartyVerificationType
} from '@property-folders/contract';
import { insertPossessiveSuffix } from '@property-folders/common/util/formatting';
import { Predicate } from '@property-folders/common/predicate';
import { EntitySettingsSigningOptions } from '@property-folders/contract/yjs-schema/entity-settings';

export const SigningPartyTypeOptions = {
  [SigningPartyType.SignOnline]: 'Email link',
  [SigningPartyType.SignInPerson]: 'On salesperson\'s screen',
  [SigningPartyType.SignWet]: 'On paper',
  [SigningPartyType.SignOnlineSms]: 'SMS link'
};

export const SignerProxyAuthorityOptions = {
  [SignerProxyType.Self]: '',
  [SignerProxyType.Auctioneer]: 'Auctioneer',
  [SignerProxyType.Salesperson]: 'Salesperson',
  [SignerProxyType.Proxy]: 'Proxy'
};

function replaceSignInPersonSalespersonToken(label: string, candidateName: string, myAgentId?: number, candidateAgentId?: number | string) {
  return label.replace(
    ' salesperson\'s ',
    myAgentId === candidateAgentId
      ? ' my '
      : candidateName === '' ? ' ' : ` ${insertPossessiveSuffix(candidateName)} `
  );
}

export function buildFilteredSigningPartyTypeOptions({
  sessionInfo,
  signingParty,
  initiator,
  salespersons,
  partyData,
  noUndefinedSalesperson,
  signingOptions,
  disallowPaper
}: {
  sessionInfo: SessionInfo | undefined,
  signingParty: SigningParty | undefined,
  initiator: SigningInitiator | undefined,
  salespersons: AgencySalesperson[] | undefined,
  // from main transaction data
  partyData: { linkedSalespersonId?: number | string, name?: string, isNotSalesperson?: boolean } | undefined,
  noUndefinedSalesperson?: boolean,
  signingOptions: EntitySettingsSigningOptions | undefined,
  disallowPaper?: boolean
}) {
  return Object.entries(SigningPartyTypeOptions).flatMap(([rawName, rawLabel]) => {
    const type = parseInt(rawName) as SigningPartyType;
    const myAgentId = sessionInfo && 'agentId' in sessionInfo ? sessionInfo.agentId : undefined;
    const signingPartySourceType = signingParty?.source.overrideType || signingParty?.source.type;

    switch (type) {
      case SigningPartyType.SignInPerson: {
        if (signingPartySourceType === SigningPartySourceType.Salesperson) {
          return [{
            name: `${rawName}_`,
            label: replaceSignInPersonSalespersonToken(rawLabel, partyData?.name || '', myAgentId, partyData?.linkedSalespersonId)
          }];
        }
        if (signingParty?.proxyAuthority === SignerProxyType.Salesperson && signingParty.proxyName && signingParty.proxyLinkedId) {
          return [{
            name: `${rawName}_${signingParty.proxyLinkedId}`,
            label: replaceSignInPersonSalespersonToken(rawLabel, signingParty.proxyName || 'salesperson', myAgentId, signingParty.proxyLinkedId)
          }];
        }
        const rList = [];
        // Initiator is not a listed Agent
        const salespersonIdList = (salespersons || []).map(sp => sp.linkedSalespersonId);
        if ((!noUndefinedSalesperson || initiator?.id) && !salespersonIdList.includes(initiator?.id)) {
          rList.push({
            name: `${rawName}_${initiator?.id}`,
            label: replaceSignInPersonSalespersonToken(rawLabel, initiator?.name || 'salesperson', myAgentId, initiator?.id)
          });
        }
        rList.push(...(salespersons || []).map(sp => {
          return {
            name: `${rawName}_${sp.linkedSalespersonId}`,
            label: replaceSignInPersonSalespersonToken(rawLabel, sp?.name || 'agent', myAgentId, sp?.linkedSalespersonId)
          };
        }));
        return rList;
      }
      case SigningPartyType.SignOnline:
      case SigningPartyType.SignOnlineSms:
        if (!partyData?.isNotSalesperson && partyData?.linkedSalespersonId === myAgentId) {
          return undefined;
        }

        return [{ name: `${rawName}_`, label: rawLabel }];

      case SigningPartyType.SignWet:
        if (!signingOptions?.allowPaper) return undefined;
        if (disallowPaper) return undefined;
        return [{ name: `${rawName}_`, label: rawLabel }];
      default:
        return [{ name: `${rawName}_`, label: rawLabel }];
    }
  }).filter(Predicate.isNotNullish);

}

export const SigningPartyVerificationTypeOptions = {
  [SigningPartyVerificationType.Sms]: 'SMS'
};

export interface IPartyDetailPaths {
  /**
   * name, email, phone can vary based on party source
   */
  data: {
    base: string;
    name: string;
    email: string;
    phone: string;
    partyType?: string;
    authority?: string;
    // update: MaybeUpdateFn<MaterialisedPropertyData>
  },
  meta: {
    base: string;
  },
  dataIsMeta?: boolean // Only used by CustomiseVerification at the time of writing this comment
}
