import { ReactNode, useContext, useState } from 'react';
import { useLightweightTransaction } from '@property-folders/components/hooks/useTransactionField';
import { FolderType, FormInstanceSigning, SigningParty, SigningSession } from '@property-folders/contract';
import { AuthApi } from '@property-folders/common/client-api/auth';
import { Avatar, TermsAndConditionsModal } from '@property-folders/components/dragged-components/signing/HostedSigning';
import ReactDOM from 'react-dom';
import { Button, Col, Container, Dropdown, Form, Offcanvas, Row } from 'react-bootstrap';
import { PropertyFormYjsDal } from '@property-folders/common/yjs-schema/property/form';
import { useEntity } from '@property-folders/components/hooks/useEntity';
import { companyTradingWithFallback } from '@property-folders/common/util/formatting';
import { YjsDocContext } from '@property-folders/components/context/YjsDocContext';

const VeryForegroundDropdownMenu = ({ zIndex, children }: {zIndex?: number, children: ReactNode}) => {
  return ReactDOM.createPortal(
    <Dropdown.Menu style={{ zIndex: zIndex || 99999 }}>
      {children}
    </Dropdown.Menu>,
    document.body);
};

export function PleaseReviewBanner({ next, cancel, formPath, partyId, formCode, formId, signingSessionId, folderType }: {
  next: () => void,
  cancel: () => void,
  formPath: string,
  partyId: string,
  formCode: string,
  formId: string,
  signingSessionId: string,
  folderType?: FolderType
}) {
  const {
    value: signing
  } = useLightweightTransaction<FormInstanceSigning>({
    parentPath: formPath,
    myPath: 'signing',
    bindToMetaKey: true
  });
  const {
    value: session
  } = useLightweightTransaction<SigningSession>({
    parentPath: formPath,
    myPath: 'signing.session',
    bindToMetaKey: true
  });
  const {
    value: signingParty
  } = useLightweightTransaction<SigningParty>({
    parentPath: formPath,
    myPath: `signing.parties.[${partyId}]`,
    bindToMetaKey: true
  });
  const { data: sessionInfo } = AuthApi.useGetAgentSessionInfo();
  const { ydoc, transactionRootKey, transactionMetaRootKey } = useContext(YjsDocContext);
  const message = signingParty?.message?.content || signing?.general?.message || '';
  const messageLines = message.split('\n').length;
  const collapsedLines = 3;
  const [ showModal, setShowModal ] = useState(true);
  const [ showFull, setShowFull ] = useState(false);
  const [ agreed, setAgreed ] = useState(false);
  const [ showAgreement, setShowAgreement ] = useState(false);
  const displayMessage = showFull ? message : message.split('\n').slice(0, collapsedLines).join('\n');
  const formDal = ydoc ? new PropertyFormYjsDal(ydoc, transactionRootKey, transactionMetaRootKey) : undefined;

  if (!(session?.initiator && signingParty && sessionInfo)) {
    console.log('things are not here yo', { session, signingParty, sessionInfo });
    return <></>;
  }

  const initiatorName = session.initiator.name;
  const localEntity = useEntity(session.initiator.entity.id);
  const entityName = companyTradingWithFallback(localEntity?.name, localEntity?.tradeName);

  return <Offcanvas className={'hosted-signing-overlay'} show={showModal} placement={'top'} style={{ height: 'max-content' }}>
    <Offcanvas.Header>
      <Container>
        <h2>Please review and act on this document</h2>
      </Container>
    </Offcanvas.Header>
    <Offcanvas.Body>
      <Container>
        <Avatar name={initiatorName} entityName={entityName} agentId={session.initiator.id} />
        <p style={{ whiteSpace: 'pre-wrap' }}>{displayMessage}</p>
        {messageLines > collapsedLines && <Button variant='link' onClick={() => setShowFull(!showFull)}>
          { showFull ? 'View Less' : 'View More' }
        </Button>}
        <Row>
          <Col lg={8}>
            <div className={'d-flex flex-column'}>
              <div>Please read the <a className={'text-decoration-underline link-dark cursor-pointer'} onClick={() => setShowAgreement(true)}>reaforms Terms and Conditions - Electronic Communications and Signing.</a></div>
              <Form.Check
                id={'agree-check'}
                type={'checkbox'}
                label={'I agree to use electronic records and signatures'}
                checked={agreed}
                onChange={e => setAgreed(e.target.checked)} />
            </div>
          </Col>
          <Col lg={4}>
            <div className={'d-flex flex-row gap-3 justify-content-end'} style={{ height: 'max-content' }}>
              <Button disabled={!agreed} variant={'primary'} onClick={() => {
                if (!agreed || !formDal) {
                  return;
                }
                setShowModal(false);
                formDal.setPartyTAndCAgreed(
                  formCode,
                  formId,
                  signingSessionId,
                  partyId,
                );
                next();
              }}>Continue</Button>
              {folderType !== FolderType.Document && <Dropdown drop={'down'}>
                <Dropdown.Toggle variant={'outline-secondary'}>Other Actions</Dropdown.Toggle>
                <VeryForegroundDropdownMenu>
                  <Dropdown.Item onClick={() => cancel()}>Decline Terms and Conditions</Dropdown.Item>
                </VeryForegroundDropdownMenu>
              </Dropdown>}
            </div>
          </Col>
        </Row>
      </Container>
    </Offcanvas.Body>
    <TermsAndConditionsModal closeAgreement={() => setShowAgreement(false)} showAgreement={showAgreement} />
  </Offcanvas>;
}
