import {
  FormSigningState,
  MaterialisedPropertyData,
  SessionInfo,
  TransactionMetaData
} from '@property-folders/contract';
import { BinderFn } from 'hooks/useImmerYjs';
import { AnyAction, Store } from 'redux';
import { FormUtil } from '@property-folders/common/util/form';

export function cancelSigning({
  formId,
  formCode,
  sessionInfo,
  metaBinder,
  dataBinder,
  store
}: {
  formId: string,
  formCode: string,
  sessionInfo?: SessionInfo,
  metaBinder: BinderFn<TransactionMetaData>,
  dataBinder: BinderFn<MaterialisedPropertyData>,
  store: Store<unknown, AnyAction>
}) {
  FormUtil.transitionSigningState({
    formCode,
    formId,
    metaBinder,
    dataBinder,
    sessionInfo,
    store
  }, {
    to: FormSigningState.None
  });
}
