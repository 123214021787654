import { FormTypes, PropertyFormYjsDal } from '@property-folders/common/yjs-schema/property/form';
import {
  ContentType,
  FormSigningState, LandType, ManifestData, ManifestType,
  RemoteSigningTypes,
  SessionInfo,
  SigningMarketingData, SigningSessionFieldType, SigningSessionSubType
} from '@property-folders/contract';
import { FileSync } from '@property-folders/common/offline/fileSync';
import { AnyAction, Store } from 'redux';
import { EntitySettingsSigningOptions } from '@property-folders/contract/yjs-schema/entity-settings';
import { UserPreferencesMain } from '@property-folders/contract/yjs-schema/user-preferences';
import { BelongingEntityMeta } from '@property-folders/common/redux-reducers/entityMeta';
import { v4 } from 'uuid';
import { FormUtil } from '@property-folders/common/util/form';
import { BaseAjaxResponse, LegacyApi } from '@property-folders/common/client-api/legacyApi';
import { WrappedFetch } from '@property-folders/common/client-api/wrappedFetch';
import { applyPdfChanges, injectSigningLocations, PdfInformationExtractor } from '@property-folders/common/util/pdf';
import { injectServeFields } from '@property-folders/common/subscription-forms/injectServeFields';
import { applyAnnexures } from '@property-folders/common/subscription-forms/applyAnnexures';
import { Predicate } from '@property-folders/common/predicate';
import { FileStorage, FileType, StorageItemSyncStatus } from '@property-folders/common/offline/fileStorage';
import { FormDescriptorRecord } from '@property-folders/common/yjs-schema/property';
import { uuidv4 } from 'lib0/random';

export async function prepareForSigning({
  dal,
  formId,
  formCode,
  initiator,
  sessionInfo,
  fileSync,
  store,
  entitySigningOpts,
  getUserPrefsData,
  memberEntities,
  overrideFormDefinition
}: {
  dal: PropertyFormYjsDal
  formId: string,
  formCode: string,
  sessionInfo?: SessionInfo,
  initiator: {
    id: number,
    name: string,
    timeZone: string,
    email: string,
    entity: {
      id: number,
      uuid?: string,
      name: string
    }
  },
  fileSync?: FileSync,
  store: Store<unknown, AnyAction>,
  entitySigningOpts: EntitySettingsSigningOptions | undefined,
  getUserPrefsData?: () => UserPreferencesMain | undefined,
  memberEntities: BelongingEntityMeta
  overrideFormDefinition?: FormDescriptorRecord;
}) {
  const formDefn = overrideFormDefinition ?? FormTypes[formCode];
  const formInstanceInitial = dal.getFormInstance(formCode, formId)!;
  const documentId = formInstanceInitial?.subscription?.documentId;
  if (!documentId) return;

  const signingSessionId = v4();
  const pdfId = v4();
  const accompanyingFileRefs = {
    propertyDataSnapshot: { id: v4(), contentType: ContentType.Json }
  };

  const hasRemoteSigning = !!formInstanceInitial?.signing?.parties?.find(p => RemoteSigningTypes.includes(p.type));

  FormUtil.transitionSigningState({
    formCode,
    formId,
    metaBinder: dal.metaBinder,
    dataBinder: dal.dataBinder,
    sessionInfo,
    store,
    entitySigningOpts
  }, {
    to: FormSigningState.OutForSigningPendingUpload,
    outForSigningData: {
      sessionId: signingSessionId,
      baseFile: {
        id: pdfId,
        contentType: ContentType.Pdf
      },
      accompanyingFileIds: accompanyingFileRefs,
      initiator,
      userPrefs: getUserPrefsData?.(),
      hasRemoteSigning,
      memberEntities
    }
  });

  const property = dal.dataBinder.get();
  const formInstance = dal.getFormInstance(formCode, formId)!;
  const parties = formInstance.signing?.parties || [];
  const originalFields = formInstance.signing?.session?.fields || [];

  const result = await LegacyApi.ajax<{
    link: string
  } & BaseAjaxResponse>('generatepdf', {
    DocumentID: documentId.toString(),
    Download: 'true',
    PFSignPrep: '2'
  });

  const pdfBytes = await WrappedFetch.bytes(result.link);
  const extractor = new PdfInformationExtractor(pdfBytes);
  const placements = await extractor.getImagePagePlacements();
  const pages = await extractor.getPageDimensions();

  const fields = formDefn.subscription?.signing?.useGroups
    ? originalFields
    : placements.map(s => {
      const party = parties.find(p => {
        const originalType = p.source.originalType ?? '';
        const typeWithNumber = originalType.replace(/\d+/, '') == originalType
          ? originalType + '1'
          : originalType as any;

        if (!s.party) {
          return false;
        }

        return (s.party.partyType + s.party.number) === typeWithNumber;
      });

      return {
        type: s.party?.fieldType === 'initial' ? SigningSessionFieldType.Initials : SigningSessionFieldType.Signature,
        subtype: s.party?.fieldType === 'initial' ? SigningSessionSubType.None : SigningSessionSubType.RenderInfoInline,
        id: uuidv4(),
        partyId: party?.id as string,
        isWetSigned: false,
        originalFieldName: s.name,
        placement: s
      };
    }).filter(s => s.partyId);

  dal.metaBinder.update(state => {
    const formFamily = state.formStates?.[formCode];
    if (!formFamily) return;

    const instance = formFamily.instances?.find(i => i.id === formId);
    if (!instance || !instance.signing?.session) return;

    instance.signing.session.fields = fields;
  });

  // need to do this to update the manifest data
  console.log('update manifest', formInstance);
  if (formInstance.signing?.session) {
    console.log('updated with fields', fields);
    formInstance.signing.session.fields = fields;
  }

  const transformedPdfBytes = await applyPdfChanges(pdfBytes, [
    async pdf => {
      await injectSigningLocations({
        parties,
        placements,
        fields,
        pdf,
        pages,
        placementStrategies: formDefn.subscription?.signing?.placementStrategies,
        debug: formDefn.debug,
        useGroups: formDefn.subscription?.signing?.useGroups
      });
    },
    async pdf => {
      await injectServeFields(pdf, formDefn.subscription?.signing?.serveFields);
    },
    async pdf => {
      await applyAnnexures(pdf, FormUtil.getAnnexuresFromFormInstance(formInstance));
    }
  ]);

  const marketingData: SigningMarketingData = {
    landType: typeof property?.landType === 'number' ? property?.landType : LandType.Unknown,
    transactionType: property?.transactionType,
    documentTemplateName: formDefn?.label, // We probably don't need to pass this
    // through here, but it'll do for now
    propertyAddress: property?.saleAddrs?.map(addr => addr?.streetAddr_parts).filter(Predicate.isNotNullish) || []
  };

  const manifestData: ManifestData = formInstance
    ? {
      manifestType: ManifestType.FormInstancePlusMarketingAndSnapshot,
      data: {
        formInstance,
        marketingData,
        accompanying: Object.assign({}, ...Object.entries(accompanyingFileRefs).map(([accKey, { id: fileId }]) => ({ [accKey]: { fileId: fileId } })))
      }
    }
    : {
      manifestType: ManifestType.None
    };
  console.log('manifest', manifestData);

  await FileStorage.write(
    pdfId,
    FileType.PropertyFile,
    ContentType.Pdf,
    new Blob([transformedPdfBytes], { type: ContentType.Pdf }),
    StorageItemSyncStatus.PendingUpload,
    {
      propertyFile: {
        propertyId: property.id,
        formId,
        formCode,
        signingSessionId
      }
    },
    store,
    manifestData,
    undefined,
    {
      propertyDataSnapshot: property
    }
  );

  FileSync.triggerSync(fileSync);
}

