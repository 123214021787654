import {
  Agent,
  FormSigningState,
  MaterialisedPropertyData,
  SessionInfo,
  TransactionMetaData
} from '@property-folders/contract';
import { BinderFn } from 'hooks/useImmerYjs';
import { AnyAction, Store } from 'redux';
import { EntitySettingsSigningOptions } from '@property-folders/contract/yjs-schema/entity-settings';
import { FormUtil } from '@property-folders/common/util/form';

export function startSigningConfiguration({
  formId,
  formCode,
  sessionInfo,
  metaBinder,
  dataBinder,
  store,
  authRep,
  entitySigningOpts
}: {
  formId: string,
  formCode: string,
  sessionInfo?: SessionInfo,
  metaBinder: BinderFn<TransactionMetaData>,
  dataBinder: BinderFn<MaterialisedPropertyData>,
  store: Store<unknown, AnyAction>,
  authRep?: Agent;
  entitySigningOpts: EntitySettingsSigningOptions
}) {
  FormUtil.transitionSigningState({
    formCode,
    formId,
    metaBinder,
    dataBinder,
    sessionInfo,
    store,
    authRep,
    entitySigningOpts
  }, {
    to: FormSigningState.Configuring
  });
}
